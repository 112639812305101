import type {SubtitleStyles} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {DeepPartial} from '@/global';

export interface SubtitleTemplateProperties {
  id: string;
  type: SubtitleTemplateType;
  sortOrder: number;
}

export interface BaseTemplateStyles {
  stylesToApply: DeepPartial<SubtitleStyles>;
}

export type TemplateStyles = NormalTemplateStyles | HighlighCurrentWordTemplateStyles | CurrentWordOnlyTemplateStyles | ProgressTemplateStyles;

export interface SubtitleTemplate {
  templateProperties: SubtitleTemplateProperties;
  templateStyles: TemplateStyles;
}

export interface NormalTemplateStyles extends BaseTemplateStyles {
  idNormalTemplate: string;
}

export interface HighlighCurrentWordTemplateStyles extends BaseTemplateStyles {
  idHighlighCurrentWordTemplate: string;
}

export interface CurrentWordOnlyTemplateStyles extends BaseTemplateStyles {
  idCurrentWordOnlyTemplate: string;
}

export interface ProgressTemplateStyles extends BaseTemplateStyles {
  idProgressTemplateStyle: string;
}

export enum SubtitleTemplateType {
  NORMAL = 'normal',
  ACTIVE_WORD_HIGHLIGHTED = 'active-word-highlighted',
  SINGLE_WORD = 'single-word',
  PROGRESS = 'progress',
}

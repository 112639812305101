import type {ReactElement} from 'react';
import React from 'react';
import type {ActivityIndicatorProps} from './activity-indicator.types';
import {ActivityIndicatorSize, ActivityIndicatorType} from './activity-indicator.types';
import styles from './activity-indicator.module.scss';

export function ActivityIndicator({size = ActivityIndicatorSize.SIZE_16, ...props}: ActivityIndicatorProps): ReactElement {
  const getIndicator = (): ReactElement => {
    switch (props.type) {
      case ActivityIndicatorType.NEW:
        return <div className={`radius-round ${styles.newIndicator}`} />;
      case ActivityIndicatorType.NEW_PULSE:
        return (
          <>
            <div className={`radius-round flex-center ${styles.newPulseIndicator}`} />
            <div className={`radius-round ${styles.newIndicator}`} />
          </>
        );
      case ActivityIndicatorType.SUCCESS:
        return getSuccessSvg();
      case ActivityIndicatorType.ERROR:
        return getErrorSvg();
      case ActivityIndicatorType.LOADING:
        return getLoader();
      default:
        throw new Error(`Unhandled activity type: ${props.type}`);
    }
  };

  const getLoaderSizeClass = (): string => {
    switch (size) {
      case ActivityIndicatorSize.SIZE_16:
        return '-xxsmall';
      default:
        throw new Error(`Unhandled size for ai circular loader size class: ${size}`);
    }
  };

  const getLoaderColorClass = (): string => {
    return '-aidark';
  };

  const getLoader = (): ReactElement => {
    return <progress className={`icon-circular-loader ${getLoaderSizeClass()} ${getLoaderColorClass()}`} />;
  };

  const getSuccessSvg = (): ReactElement => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M2.93934 6.93934L3.46967 7.46967L2.93934 6.93934C2.35355 7.52513 2.35355 8.47487 2.93934 9.06066L3.46967 8.53033L2.93934 9.06066L5.93934 12.0607C6.52513 12.6464 7.47487 12.6464 8.06066 12.0607L14.0607 6.06066C14.6464 5.47487 14.6464 4.52513 14.0607 3.93934C13.4749 3.35355 12.5251 3.35355 11.9393 3.93934L7 8.87868L5.06066 6.93934C4.47487 6.35355 3.52513 6.35355 2.93934 6.93934Z"
          fill="#007C5E"
          stroke="#D7F8F0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const getErrorSvg = (): ReactElement => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M4.5 8C4.5 6.34562 5.64783 4.95933 7.19056 4.59407C6.77519 4.86081 6.5 5.32684 6.5 5.85714V8C6.5 8.41972 6.67239 8.79917 6.95022 9.07143C6.67239 9.34368 6.5 9.72314 6.5 10.1429C6.5 10.6732 6.77519 11.1392 7.19056 11.4059C5.64783 11.0407 4.5 9.65438 4.5 8ZM9.5 5.85714C9.5 5.32684 9.22481 4.86081 8.80944 4.59407C10.3522 4.95933 11.5 6.34562 11.5 8C11.5 9.65032 10.3578 11.0339 8.82078 11.4032C9.2338 11.136 9.50714 10.6714 9.50714 10.1429C9.50714 9.72134 9.33327 9.34043 9.05334 9.06793C9.32909 8.79593 9.5 8.41792 9.5 8V5.85714ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          fill="#9C002F"
          stroke="#FFE4ED"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const getClassForSize = (): string => {
    switch (size) {
      case ActivityIndicatorSize.SIZE_16:
        return styles.size16;
      default:
        throw new Error(`Unhandled size for activity indicator: ${size}`);
    }
  };
  return <div className={`flex-center ${styles.container} ${getClassForSize()} ${props.className ?? ''}`}>{getIndicator()}</div>;
}

import type {AddSubtitleData, AddWordData} from '@Libraries/add-media-library';
import {ElementDataType} from '@Libraries/add-media-library';
import type {GeneratedFromType, TranscriptGeneratedFrom, TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {AiTranscriptSupportedLanguage} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {AiTranscriptForItemWithParams} from '@Hooks/poster-editor/useAiTranscriptGeneration';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import {getUniqueString, removePunctuationFromWord} from '@Utils/string.util';

export const NO_SPEECH_DETECTED_ERROR_CODE = 'NO_SPEECH_DETECTED';

export interface GeneratedTranscriptWordsData {
  id: string;
  start: number;
  end: number;
  text: string;
}

export interface GeneratedTranscriptSubtitlesData {
  id: string;
  start: number;
  end: number;
  text: string;
  words: Array<GeneratedTranscriptWordsData>;
}

export interface GeneratedAiTranscriptData {
  text: string;
  subtitles: Array<GeneratedTranscriptSubtitlesData>;
}

export const doesTranscriptForItemNeedRegeneration = (params: AiTranscriptForItemWithParams, existingTranscriptItem: TranscriptItemObject): boolean => {
  return (
    !doTrimTimesMatchGeneratedTranscript(params.startTime, params.endTime, params.onPosterStartTime, existingTranscriptItem) ||
    doesTranscriptHaveUserEditedSubtitles(existingTranscriptItem) ||
    hasSpeedBeenChangedAfterAiTranscriptGeneration(params.speed, existingTranscriptItem.generatedFrom?.speed) ||
    hasLoopCountChangedAfterAiTranscriptGenerationForVideo(params.loopCycles, existingTranscriptItem.generatedFrom?.loopCycles)
  );
};

export const doTrimTimesMatchGeneratedTranscript = (trimStartTime: number, trimEndTime: number, onPosterStartTime: number, generatedTranscript: TranscriptItemObject): boolean => {
  if (!generatedTranscript.generatedFrom) {
    return false;
  }
  const generatedFromStartTime = generatedTranscript.generatedFrom.startTime;
  const generatedFromEndTime = generatedTranscript.generatedFrom.endTime;
  const generatedFromOnPosterStartTime = generatedTranscript.generatedFrom.onPosterStartTime;

  return trimStartTime === generatedFromStartTime && trimEndTime === generatedFromEndTime && onPosterStartTime === generatedFromOnPosterStartTime;
};

export const generateAiTranscriptAndGetSubtitles = async (
  audioHashedFilename: string,
  trimStartTime: number,
  trimEndTime: number,
  mediaType: GeneratedFromType,
  loopCycles = 1,
  speed = 1,
  language = AiTranscriptSupportedLanguage.ENGLISH
): Promise<GeneratedAiTranscriptData> => {
  return (await window.PMW.writeLocal('aitranscript/generateAiTranscriptAndGetSubtitles', {
    hashedFilename: audioHashedFilename,
    startTime: trimStartTime,
    endTime: trimEndTime,
    mediaType,
    loopCycles,
    speed,
    language,
  })) as Promise<GeneratedAiTranscriptData>;
};

export const replaceExistingTranscriptOnPosterForGeneratedAiTranscript = async (
  existingTranscriptItem: TranscriptItemObject,
  uidForTranscriptToAdd: string,
  generatedTranscript: GeneratedAiTranscriptData,
  onPosterStartTime: number,
  generatedFrom: TranscriptGeneratedFrom,
  selectOnAdd: boolean
): Promise<void> => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  const subtitles: Array<AddSubtitleData> = [];
  for (const subtitle of generatedTranscript.subtitles) {
    const words: Array<AddWordData> = [];

    for (const word of subtitle.words) {
      words.push({
        id: word.id,
        endTime: word.end + onPosterStartTime,
        text: word.text,
        startTime: word.start + onPosterStartTime,
      });
    }

    subtitles.push({
      id: subtitle.id,
      endTime: subtitle.end + onPosterStartTime,
      text: subtitle.text,
      startTime: subtitle.start + onPosterStartTime,
      words,
    });
  }

  await currentPage.items.replaceItems.replaceTranscript(
    existingTranscriptItem.uid,
    uidForTranscriptToAdd,
    {
      areAIGenerated: false,
      type: ElementDataType.TRANSCRIPT,
      subtitles,
    },
    {
      selectOnAdd,
      optionsToAdd: {generatedFrom},
    }
  );
};

export const addNewTranscriptToPosterForGeneratedAiTranscript = async (
  uidForTranscriptToAdd: string,
  generatedTranscript: GeneratedAiTranscriptData,
  onPosterStartTime: number,
  generatedFrom: TranscriptGeneratedFrom,
  selectOnAdd: boolean
): Promise<void> => {
  const poster = window.posterEditor?.whiteboard;
  if (!poster) {
    return;
  }

  const currentPage = poster.getCurrentPage();

  const subtitles: Array<AddSubtitleData> = [];
  for (const subtitle of generatedTranscript.subtitles) {
    const words: Array<AddWordData> = [];

    for (const word of subtitle.words) {
      words.push({
        id: word.id,
        endTime: word.end + onPosterStartTime,
        text: word.text,
        startTime: word.start + onPosterStartTime,
      });
    }

    subtitles.push({
      id: subtitle.id,
      endTime: subtitle.end + onPosterStartTime,
      text: subtitle.text,
      startTime: subtitle.start + onPosterStartTime,
      words,
    });
  }

  await currentPage.items.addItems.addTranscriptItem(
    {
      areAIGenerated: false,
      type: ElementDataType.TRANSCRIPT,
      subtitles,
    },
    {
      optionsToAdd: {uid: uidForTranscriptToAdd, generatedFrom},
      selectOnAdd,
    }
  );
};

export const onGenerateAiTranscriptError = (e: Error): void => {
  console.error('oops', e.message);
};

export const showAiSubtitlesUpsellDialog = async (): Promise<void> => {
  window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_AI_SUBTITLES);
};

export const getKeyForAiTranscriptGenerationResult = (generatedFrom: TranscriptGeneratedFrom): string => {
  return `${generatedFrom.hashedFilename}_${generatedFrom.type}_${generatedFrom.startTime}_${generatedFrom.endTime}`;
};

export const doesTranscriptHaveUserEditedSubtitles = (transcript: TranscriptItemObject): boolean => {
  for (const [, subtitle] of Object.entries(transcript.subtitlesHashmap)) {
    if (subtitle.hasUserEdited) {
      return true;
    }
  }

  return false;
};

export const hasSpeedBeenChangedAfterAiTranscriptGeneration = (itemSpeed: number, transcriptGeneratedFromSpeed?: number): boolean => {
  if (!transcriptGeneratedFromSpeed) {
    return true;
  }

  return itemSpeed !== transcriptGeneratedFromSpeed;
};

export const hasLoopCountChangedAfterAiTranscriptGenerationForVideo = (itemLoopCycles: number, transcriptGeneratedFromLoopCycles?: number): boolean => {
  if (!transcriptGeneratedFromLoopCycles) {
    return true;
  }

  return itemLoopCycles !== transcriptGeneratedFromLoopCycles;
};

export const getAiTranscriptParamsForAudio = (audioItem: AudioItemObject): AiTranscriptForItemWithParams => {
  return {
    uid: audioItem.uid,
    type: 'audio',
    hashedFilename: audioItem.hashedFilename,
    startTime: audioItem.audioPlayer.trim.startTime,
    endTime: audioItem.audioPlayer.trim.endTime,
    onPosterStartTime: audioItem.onPosterStartTime,
    loopCycles: 1,
    speed: audioItem.audioPlayer.speed,
  };
};

export const getAiTranscriptParamsForVideo = (videoItem: VideoItemObject, loopCycles: number): AiTranscriptForItemWithParams => {
  return {
    uid: videoItem.uid,
    type: 'video',
    hashedFilename: videoItem.hashedFilename,
    startTime: videoItem.startTime,
    endTime: videoItem.endTime,
    onPosterStartTime: 0,
    loopCycles,
    speed: 1,
  };
};

export const getSubtitlesWordArrayAfterTextEdited = (editedText: string, subtitleStartTime: number, subtitleEndTime: number): Array<WordObject> => {
  const wordsToMake = editedText.trim().split(' ');
  const numberOfWords = wordsToMake.length;
  const durationOfEachWord = (subtitleEndTime - subtitleStartTime) / numberOfWords;

  const newWords: Array<WordObject> = [];

  let startTime = subtitleStartTime;

  for (let i = 0; i < numberOfWords; i++) {
    const cleanWord = removePunctuationFromWord(wordsToMake[i]);

    newWords.push({
      id: getUniqueString(),
      text: cleanWord,
      startTime,
      endTime: startTime + durationOfEachWord,
    });

    startTime = startTime + durationOfEachWord + 0.01;
  }

  return newWords;
};

import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {SyncWordToPosterClock} from '@PosterWhiteboard/items/transcript-item/subtitle/word/sync-word-to-poster-clock';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import type {StartAndEndIndicesOfWord} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {getUniqueString} from '@Utils/string.util';
import type {DeepPartial} from '@/global';

export class Word extends CommonMethods {
  public wordId = '';
  public subtitle: Subtitle;
  public startTime = 0;
  public endTime = 0;
  public text = '';

  private readonly syncToPosterClock: SyncWordToPosterClock;

  public constructor(subtitle: Subtitle) {
    super();
    this.wordId = getUniqueString();
    this.subtitle = subtitle;
    this.syncToPosterClock = new SyncWordToPosterClock(this);
  }

  public updateFromObject(wordObject: DeepPartial<WordObject>): void {
    this.copyVals(wordObject);
  }

  public toObject(): WordObject {
    return {
      id: this.wordId,
      startTime: this.startTime,
      endTime: this.endTime,
      text: this.text,
    };
  }

  public addWordToSubtitle(): void {
    this.subtitle.words.push(this);
    this.syncToPosterClock.initSyncToPosterClock();
  }

  public syncWord(): void {
    this.syncToPosterClock.syncToPage(window.posterEditor?.whiteboard?.getCurrentTime() ?? 0);
  }

  public unsyncWord(): void {
    this.syncToPosterClock.unload();
  }

  public getStartAndEndIndicesOfCurrentWordInSubtitle(): StartAndEndIndicesOfWord {
    if (this.subtitle.animationStyle === SubtitleTemplateType.SINGLE_WORD) {
      return {start: 0, end: [...this.subtitle.textFabricObject.text].length};
    }

    let indexOfCurrentWordInWordsArray = -1;

    Object.values(this.subtitle.words).forEach((word, index) => {
      if (word.text === this.text && word.startTime === this.startTime && word.endTime === this.endTime) {
        indexOfCurrentWordInWordsArray = index;
      }
    });

    if (indexOfCurrentWordInWordsArray === -1) {
      throw new Error('Current word not found in subtitle words.');
    }

    let startIndexOfCurrentWordInSentence = 0;

    // Split the text into graphemes to ensure proper handling of characters like 🎵
    const words = this.subtitle.text.split(' ').map((word) => [...word]);

    for (let i = 0; i < indexOfCurrentWordInWordsArray; i++) {
      startIndexOfCurrentWordInSentence += words[i].length + 1; // +1 for the space
    }

    const currentWordGraphemes = [...this.text];
    return {
      start: startIndexOfCurrentWordInSentence,
      end: startIndexOfCurrentWordInSentence + currentWordGraphemes.length,
    };
  }

  public applyActiveWordStyleIfNeeded(): void {
    const type = this.subtitle.animationStyle;

    if (type === SubtitleTemplateType.NORMAL) {
      return;
    }

    this.subtitle.resetTextFabricObjectSelectionStyles();

    if (this.subtitle.animationStyle === SubtitleTemplateType.SINGLE_WORD) {
      this.showCurrentWordOnly();
    } else {
      this.applyActiveWordStyles();
    }

    this.subtitle.transcriptItem.fabricObject.set('dirty', true);

    this.subtitle.transcriptItem.page.fabricCanvas.requestRenderAll();
  }

  private applyActiveWordStyles(): void {
    const {start, end} = this.getStartAndEndIndicesOfCurrentWordInSubtitle();

    this.subtitle.textFabricObject.setSelectionStyles(
      {
        ...this.subtitle.activeWordTextStyles.getTextStyles(this.subtitle.backgroundFabricObject.width, this.subtitle.backgroundFabricObject.height),
        fontFamily: this.subtitle.activeWordTextStyles.fontFamily,
      },
      this.subtitle.animationStyle === SubtitleTemplateType.PROGRESS ? 0 : start,
      end
    );

    this.subtitle.applyActiveTextStrokeBetweenIndices(this.subtitle.animationStyle === SubtitleTemplateType.PROGRESS ? 0 : start, end);
  }

  private showCurrentWordOnly(): void {
    if (!this.subtitle.textFabricObject.isEditing || this.subtitle.transcriptItem.page.poster.isPlaying()) {
      this.subtitle.textFabricObject.set('text', this.text);

      this.subtitle.transcriptItem.adjustLayoutAndAlignment();
    }
  }
}

export const createAndAddWordFromObject = (subtitle: Subtitle, wordObject: DeepPartial<WordObject>): Word => {
  const word = new Word(subtitle);
  word.updateFromObject(wordObject);
  word.addWordToSubtitle();
  return word;
};

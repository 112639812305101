import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';
import type {Word} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word';

export class SyncWordToPosterClock extends CommonMethods {
  public word: Word;
  private syncing = false;
  private readonly boundSyncToPage;

  constructor(word: Word) {
    super();
    this.word = word;
    this.boundSyncToPage = this.syncToPage.bind(this);
  }

  public initSyncToPosterClock(): void {
    this.word.subtitle.transcriptItem.page.poster.on(PosterEvent.PAGE_TIME_UPDATED, this.boundSyncToPage);
  }

  public unload(): void {
    this.word.subtitle.transcriptItem.page.poster.off(PosterEvent.PAGE_TIME_UPDATED, this.boundSyncToPage);
  }

  public syncToPage(time: number): void {
    if (this.syncing) {
      return;
    }

    this.syncing = true;
    this.syncWordState(time);
    this.syncing = false;
  }

  private syncWordState(posterTime: number): void {
    if (posterTime >= this.word.startTime && posterTime <= this.word.endTime) {
      this.word.applyActiveWordStyleIfNeeded();
    }
  }
}

import type {GridUserImageItemStorage} from '@Components/base-grid/components/grid-user-image-item';
import type {GridUserVideoItemStorage} from '@Components/base-grid/components/grid-user-video-item';
import type {GridUserAudioItemStorage} from '@Components/base-grid/components/grid-user-audio-item';
import type {USER_VIDEO_SOURCE, UserVideoVOResponse} from '@Libraries/user-video-library';
import type {RGB} from '@Utils/color.util';
import type {USER_AUDIO_SOURCE, UserAudioVOResponse} from '@Libraries/user-audio-library';
import type {LayoutData, ScheduleDataType} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import type {Cell, CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {ImageItemSourceType} from '@Libraries/image-item.library';
import type {MorphType} from '@Libraries/fancy-text-library';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';
import type {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {TranscriptGeneratedFrom, TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {SOURCE} from '@Libraries/stock-graphic-library';
import type {GridStockImageItemStorage} from '@Components/base-grid/components/grid-stock-image-item';
import type {TextStylesObject, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {SubtitleTemplateProperties, SubtitleTemplate} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {TemplateStyles} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {SubtitleStyles} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {UserImageVOResponse} from './user-image-library';
import {getHashedFilenameForStockAsset} from './user-image-library';
import type {DeepPartial} from '@/global';

export type ElementData =
  | FancyTextData
  | TextData
  | ImageData
  | TempImageData
  | VideoData
  | PMWShapeData
  | PMWIconData
  | AnimatedStickerData
  | AudioData
  | QRCodeData
  | VectorData
  | TabsData
  | TableData
  | ScheduleLayoutData
  | MenuData
  | SlideshowData
  | PMWExtractedGettyStickerData
  | PMWStillStickerData
  | TranscriptData;

export type ElementDataWithUid = ElementData & {uid: string};
export type StickerData = AnimatedStickerData | PMWStillStickerData;

export enum ElementDataType {
  IMAGE = 'image',
  QR_CODE = 'qrcode',
  ANIMATED_STICKER = 'animated_sticker',
  PMW_SHAPE = 'pmw_shape',
  VIDEO = 'video',
  TEXT = 'text',
  FANCY_TEXT = 'fancytext',
  AUDIO = 'audio',
  VECTOR = 'vector',
  TABLE = 'table',
  MENU = 'menu',
  TAB = 'tabs',
  SLIDESHOW = 'slideshow',
  ICON = 'icon',
  EXTRACTED_GETTY_STICKER = 'extracted_getty_sticker',
  STILL_STICKER = 'still_sticker',
  TRANSCRIPT = 'transcript',
}

export interface SlideshowData extends ElementDataBase {
  type: ElementDataType.SLIDESHOW;
}

export interface TableData extends ElementDataBase {
  type: ElementDataType.TABLE;
  columns: number;
  rows: number;
  tableData: Array<Array<string>>;
  highlightedRows: Array<number>;
}

export interface ScheduleLayoutData extends ElementDataBase {
  type: ElementDataType.TABLE;
  columns: number;
  rows: number;
  tableData: Array<Array<ScheduleDataType>>;
  highlightedRows: Array<number>;
  columnsData: Array<CellType>;
  layoutStyle: LayoutData;
  unusedData: Array<Array<ScheduleDataType>>;
}

export interface MenuData extends ElementDataBase {
  type: ElementDataType.MENU;
  layoutDataMap: Record<string, Array<Cell>>;
  rows: number;
  columns: number;
  itemIds: Array<string>;
  layoutStyle: LayoutTypes;
}

export interface TabsData extends ElementDataBase {
  type: ElementDataType.TAB;
  text?: string;
}

export interface QRCodeData extends ElementDataBase {
  type: ElementDataType.QR_CODE;
}

export interface FancyTextData extends ElementDataBase {
  type: ElementDataType.FANCY_TEXT;
  text: string;
  idFancyText: string;
  effect: string;
  colors: Array<RGB>;
  fontFamily: string;
  morphType: MorphType;
  morphAmount: number;
}

export interface TextData extends ElementDataBase {
  type: ElementDataType.TEXT;
  text?: string;
}

export interface AddTranscriptOpts {
  optionsToAdd?: DeepPartial<TranscriptItemObject>;
  selectOnAdd?: boolean;
}

export interface TranscriptData extends ElementDataBase {
  type: ElementDataType.TRANSCRIPT;
  subtitles: Array<AddSubtitleData>;
  areAIGenerated: boolean;
  styles?: SubtitleStyles;
  selectedSubtitleTemplateId?: string;
}

export interface AddWordData {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
}

export interface AddSubtitleData {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
  words: Array<AddWordData>;
}

export interface ImageData extends ElementDataBase, MediaDimensions {
  type: ElementDataType.IMAGE;
  hashedFilename: string;
  extension: string;
  source: ImageItemSourceType;
  hasTransparency: boolean;
  uploaderId: number;
  replaceId?: string;
}

export interface AiImageDataForDataUrlImage extends Pick<ImageData, 'type' | 'source'> {}
export interface StockImageDataForDataUrlImage extends Pick<ImageData, 'type' | 'hashedFilename' | 'source' | 'extension'> {}

export interface TempImageData extends ElementDataBase, MediaDimensions {
  type: ElementDataType.IMAGE;
  source: ImageItemSourceType;
  uid: string;
  dataUrl: string;
}

interface MediaDimensions {
  width?: number;
  height?: number;
}

export interface AnimatedStickerData extends ElementDataBase {
  type: ElementDataType.ANIMATED_STICKER;
  hashedFilename: string;
  duration: number;
  frameRate: number;
  highResAnimatedSprite: AnimatedSprite;
  screenAnimatedSprite: AnimatedSprite;
}

export interface PMWShapeData extends ElementDataBase {
  type: ElementDataType.PMW_SHAPE;
  hashedFilename: string;
}

export interface PMWIconData extends ElementDataBase {
  type: ElementDataType.ICON;
  hashedFilename: string;
}

export interface PMWStillStickerData extends ElementDataBase {
  type: ElementDataType.STILL_STICKER;
  hashedFilename: string;
  source?: ImageItemSourceType;
}

export interface PMWExtractedGettyStickerData extends ElementDataBase {
  type: ElementDataType.EXTRACTED_GETTY_STICKER;
  hashedFilename: string;
  source?: ImageItemSourceType;
}

export interface VideoData extends ElementDataBase, MediaDimensions {
  type: ElementDataType.VIDEO;
  hashedFilename: string;
  extension: string;
  duration: number;
  frameRate: number;
  source: USER_VIDEO_SOURCE;
  hasTransparency: boolean;
}

export interface AudioData extends ElementDataBase {
  type: ElementDataType.AUDIO;
  filename: string;
  extension: string;
  duration: number;
  src: USER_AUDIO_SOURCE;
  name: string;
}

export interface VectorData extends ElementDataBase {
  type: ElementDataType.VECTOR;
  hashedFilename: string;
  name: string;
  fillColor: Array<RGB>;
}

export interface ElementDataBase {
  type: ElementDataType;
}

export const isTempImageData = (data: any): data is TempImageData => {
  return (data as TempImageData).type === ElementDataType.IMAGE && 'source' in data && 'uid' in data && 'dataUrl' in data;
};

export const getStockImageDataFromGridStockImageItemStorage = (gridData: GridStockImageItemStorage): StockImageDataForDataUrlImage => {
  return {
    type: ElementDataType.IMAGE,
    hashedFilename: getHashedFilenameForStockAsset(gridData.source as SOURCE, gridData.id),
    source: gridData.source,
    extension: gridData.data.type as string,
  };
};

export const getGeneratedAiImageDataFromGridItemStorage = (gridData: GridStockImageItemStorage): AiImageDataForDataUrlImage => {
  return {
    type: ElementDataType.IMAGE,
    source: gridData.source,
  };
};

export const getImageDataFromUserImageVO = (userImageVO: UserImageVOResponse): ImageData => {
  return {
    type: ElementDataType.IMAGE,
    hashedFilename: userImageVO.filename,
    source: userImageVO.src,
    extension: userImageVO.ext,
    hasTransparency: userImageVO.hasTransparency,
    uploaderId: parseInt(userImageVO.uploaderId, 10),
  };
};

export const getImageDataFromGridUserImageItemStorage = (gridItem: GridUserImageItemStorage): ImageData => {
  return {
    type: ElementDataType.IMAGE,
    hashedFilename: gridItem.hashedFilename,
    source: gridItem.source,
    extension: gridItem.extension,
    hasTransparency: gridItem.hasTransparency,
    uploaderId: gridItem.uploaderId,
    width: gridItem.width,
    height: gridItem.height,
  };
};

export const getVideoDataFromGridUserVideoItemStorage = (gridItem: GridUserVideoItemStorage): VideoData => {
  return {
    type: ElementDataType.VIDEO,
    hashedFilename: gridItem.hashedFilename,
    source: gridItem.source,
    extension: gridItem.extension,
    hasTransparency: gridItem.hasTransparency,
    duration: gridItem.duration,
    frameRate: gridItem.frameRate,
    width: gridItem.width,
    height: gridItem.height,
  };
};

export const getAudioDataFromGridUserAudioItemStorage = (gridItem: GridUserAudioItemStorage): AudioData => {
  return {
    type: ElementDataType.AUDIO,
    filename: gridItem.hashedFilename,
    src: gridItem.source,
    extension: gridItem.extension,
    duration: gridItem.duration,
    name: gridItem.name,
  };
};

export const getAudioDataFromUserAudioVO = (userAudioVo: UserAudioVOResponse): AudioData => {
  return {
    type: ElementDataType.AUDIO,
    name: userAudioVo.name,
    extension: userAudioVo.ext,
    src: userAudioVo.src,
    filename: userAudioVo.filename,
    duration: userAudioVo.duration,
  };
};

export const getVideoDataFromUserVideoVO = (userVideoVO: UserVideoVOResponse): VideoData => {
  return {
    type: ElementDataType.VIDEO,
    hashedFilename: userVideoVO.filename,
    source: userVideoVO.src,
    duration: userVideoVO.duration,
    frameRate: userVideoVO.frameRate,
    extension: userVideoVO.ext,
    hasTransparency: userVideoVO.hasTransparency,
  };
};

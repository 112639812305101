import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {SyncSubtitleToPosterClock} from '@PosterWhiteboard/items/transcript-item/sync-subtitle-to-poster-clock';
import {rgbToHexString} from '@Utils/color.util';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {FixedLayout, Group, IText, LayoutManager, Point, Rect, type Shadow, Textbox} from '@postermywall/fabricjs-2';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import {ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import {addFontsAsync} from '@Libraries/font-library';
import type {Word} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word';
import {createAndAddWordFromObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word';
import type {WordObject} from '@PosterWhiteboard/items/transcript-item/subtitle/word/word.types';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import {SHADOW_REFERENCE_DIMENSION} from '@PosterWhiteboard/items/item/item.types';
import {getSubtitlesWordArrayAfterTextEdited} from '@Libraries/ai-transcript.library';
import {getDefaultSelectedSubtitleTemplateProperties} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles';
import type {DeepPartial} from '@/global';

export const BACKGROUND_PADDING = 12;

export class Subtitle extends CommonMethods {
  public transcriptItem: TranscriptItem;
  public subtitleUID = '';
  public text = '';
  public words: Word[] = [];
  public startTime = 0;
  public endTime = 0;
  public isInitialzed = false;
  public hasUserEdited = false;
  public selectedTemplateId: string = getDefaultSelectedSubtitleTemplateProperties().id;
  public animationStyle: SubtitleTemplateType = getDefaultSelectedSubtitleTemplateProperties().type;
  public sentenceTextStyles: TextStyles = new TextStyles();
  public activeWordTextStyles: TextStyles = new TextStyles();
  public backgroundFill: Fill = new Fill();
  public aura: ItemAura = new ItemAura();
  public backgroundBorderRadius = 0;

  public backgroundFabricObject!: Rect;
  public textFabricObject!: Textbox;
  public fabricObject!: Group;
  private readonly syncToPosterClock: SyncSubtitleToPosterClock;

  private previousWordDuringOnCanvasEditing?: string;

  public constructor(transcriptItem: TranscriptItem) {
    super();
    this.transcriptItem = transcriptItem;
    this.syncToPosterClock = new SyncSubtitleToPosterClock(this);
  }

  public toObject(): SubtitleObject {
    const wordObjects: WordObject[] = [];

    for (const word of this.words) {
      wordObjects.push(word.toObject());
    }

    return {
      subtitleUID: this.subtitleUID,
      text: this.text,
      startTime: this.startTime,
      endTime: this.endTime,
      hasUserEdited: this.hasUserEdited,
      sentenceTextStyles: this.sentenceTextStyles.toObject(),
      activeWordTextStyles: this.activeWordTextStyles.toObject(),
      backgroundBorderRadius: this.backgroundBorderRadius,
      backgroundFill: this.backgroundFill.toObject(),
      aura: this.aura.toObject(),
      selectedTemplateId: this.selectedTemplateId,
      animationStyle: this.animationStyle,
      words: wordObjects,
    };
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      this.isInitialzed = true;
      this.fabricObject = this.getFabricObject();
      this.initEvents();
    }
  }

  public copyVals(obj: DeepPartial<SubtitleObject>): void {
    const {sentenceTextStyles, activeWordTextStyles, aura, backgroundFill, ...plainObj} = obj;
    super.copyVals(plainObj);
    this.sentenceTextStyles.copyVals(sentenceTextStyles);
    this.activeWordTextStyles.copyVals(activeWordTextStyles);
    this.aura.copyVals(aura);
    this.backgroundFill.copyVals(backgroundFill);
  }

  public onAddedToTranscript(): void {
    this.syncToPosterClock.initSyncToPosterClock();
  }

  public async updateFromObject(subtitleObject: DeepPartial<SubtitleObject>, {updateRedux = true, undoable = true}: UpdateFromObjectOpts = {}): Promise<void> {
    const {words, ...otherSubtitleObject} = subtitleObject;
    this.copyVals({
      ...otherSubtitleObject,
    });

    if (otherSubtitleObject.animationStyle) {
      if (otherSubtitleObject.animationStyle !== SubtitleTemplateType.SINGLE_WORD) {
        this.previousWordDuringOnCanvasEditing = undefined;
      }

      if (this.isInitialzed) {
        this.resetTextFabricObjectSelectionStyles();
      }
    }

    await this.ensureFontsAreLoaded();
    await this.init();

    if (subtitleObject.hasUserEdited) {
      const durationOfEachWord =
        ((subtitleObject.endTime ?? this.endTime) - (subtitleObject.startTime ?? this.startTime)) / (subtitleObject.text ?? this.text).trim().split(' ').length;

      let startTime = subtitleObject.startTime ?? this.startTime;
      for (const word of this.words) {
        word.startTime = startTime;
        word.endTime = startTime + durationOfEachWord;

        startTime = startTime + durationOfEachWord + 0.01;
      }
    }

    if (words) {
      for (const word of this.words) {
        word.unsyncWord();
      }

      this.words = [];

      for (const word of words) {
        createAndAddWordFromObject(this, word);
      }
    }

    this.updateFabricObject();

    if (undoable) {
      this.transcriptItem.page.poster.history.addPosterHistory();
    }

    if (updateRedux) {
      this.transcriptItem.page.poster.redux.updateReduxData();
    }

    this.syncSubtitle();
  }

  public getSmallestWidthNeededByFabricTextBox(): number {
    return this.textFabricObject.getMinWidth() + BACKGROUND_PADDING * 2;
  }

  public syncSubtitle(): void {
    this.syncToPosterClock.syncToPage(window.posterEditor?.whiteboard?.getCurrentTime() ?? 0);

    for (const word of this.words) {
      word.syncWord();
    }
  }

  public onRemove(): void {
    this.syncToPosterClock.unload();
    for (const word of this.words) {
      word.unsyncWord();
    }
  }

  public getFabricObject(): Group {
    this.textFabricObject = new Textbox(this.text, {
      lockMovementY: true,
      lockMovementX: true,
      lockScalingX: true,
      lockScalingY: true,
      __PMWID: this.transcriptItem.uid,
      hasControls: false,
      left: BACKGROUND_PADDING,
      top: BACKGROUND_PADDING,
    });

    this.backgroundFabricObject = new Rect({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
      strokeWidth: 0,
      __PMWID: this.transcriptItem.uid,
      evented: false,
      selectable: false,
    });

    return new Group([this.backgroundFabricObject, this.textFabricObject], {
      subTargetCheck: true,
      interactive: true,
      __PMWID: this.transcriptItem.uid,
      layoutManager: new LayoutManager(new FixedLayout()),
      lockMovementY: true,
      lockMovementX: true,
      hasControls: false,
      selectable: false,
    });
  }

  public setMaxWidth(width: number): void {
    const maxWidth = this.getMaxTextFabricObjectWidth();
    const newWidth = width > maxWidth ? maxWidth : width;

    this.backgroundFabricObject.set({
      width: newWidth - this.backgroundFabricObject.strokeWidth,
    });
    this.textFabricObject.set({
      width: this.backgroundFabricObject.width - BACKGROUND_PADDING * 2,
    });
    this.backgroundFabricObject.set({
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });

    this.updateGroupDimensions();
    this.centerAlignItems();
  }

  public getCurrentWord(): Word | undefined {
    const currentTime = this.transcriptItem.page.poster.getCurrentTime();
    for (const word of this.words) {
      if (word.startTime <= currentTime && word.endTime >= currentTime) {
        return word;
      }
    }
    return undefined;
  }

  public getMaxTextFabricObjectWidth(): number {
    const text = new IText(this.textFabricObject.text, {
      shadow: this.transcriptItem.aura.getItemAura(this.getScaleForShadow()),
      ...this.sentenceTextStyles.getTextStyles(this.textFabricObject.width, this.textFabricObject.height),
    });
    return text.width + BACKGROUND_PADDING * 2 + 1;
  }

  public show(): void {
    if (!this.fabricObject.visible) {
      this.fabricObject.set({
        visible: true,
        evented: true,

        subTargetCheck: true,
        interactive: true,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  public hide(): void {
    if (this.fabricObject.visible) {
      this.fabricObject.set({
        visible: false,
        evented: false,

        subTargetCheck: false,
        interactive: false,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  public updateFabricObject(): void {
    this.resetTextFabricObjectSelectionStyles();

    this.textFabricObject.set({
      ...this.sentenceTextStyles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
      shadow: this.getShadow(),
      fontFamily: this.sentenceTextStyles.fontFamily,
    });

    this.applyTextStroke();

    this.backgroundFabricObject.set({
      rx: this.backgroundBorderRadius,
      ry: this.backgroundBorderRadius,
      fill: this.backgroundFill.getFill(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
    });

    this.updateBackgroundDimensions();
    this.updateGroupDimensions();
  }

  public resetTextFabricObjectSelectionStyles(): void {
    if (this.animationStyle !== SubtitleTemplateType.SINGLE_WORD) {
      this.textFabricObject.set('text', this.text);
    }

    this.textFabricObject.setSelectionStyles(
      {
        shadow: this.getShadow(),
        ...this.sentenceTextStyles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
        fontFamily: this.sentenceTextStyles.fontFamily,
      },
      0,
      this.text.length
    );

    this.resetSentenceTextStokeBetweenIndices(0, this.text.length);
  }

  public addSubtitleToTranscript(): void {
    this.transcriptItem.addSubtitle(this);
    this.onAddedToTranscript();
  }

  public resetSentenceTextStokeBetweenIndices(start: number, end: number): void {
    if (this.sentenceTextStyles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: this.sentenceTextStyles.fontSize * this.sentenceTextStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
          strokeLineJoin: 'round',
          paintFirst: 'stroke',
          stroke: rgbToHexString(this.sentenceTextStyles.strokeColor, 1),
        },
        start,
        end
      );
    } else if (!this.sentenceTextStyles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: 0,
          strokeLineJoin: 'miter',
          paintFirst: 'fill',
          stroke: undefined,
        },
        start,
        end
      );
    }
  }

  public applyActiveTextStrokeBetweenIndices(start: number, end: number): void {
    if (this.activeWordTextStyles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: this.activeWordTextStyles.fontSize * this.activeWordTextStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
          strokeLineJoin: 'round',
          paintFirst: 'stroke',
          stroke: rgbToHexString(this.activeWordTextStyles.strokeColor, 1),
        },
        start,
        end
      );
    } else if (!this.activeWordTextStyles.stroke) {
      this.textFabricObject.setSelectionStyles(
        {
          strokeWidth: 0,
          strokeLineJoin: 'miter',
          paintFirst: 'fill',
          stroke: undefined,
        },
        start,
        end
      );
    }
  }

  public getScaleForShadow(): number {
    const maxDimension = Math.max(this.transcriptItem.height, this.transcriptItem.width);
    return maxDimension / SHADOW_REFERENCE_DIMENSION;
  }

  protected getShadow(): Shadow | null {
    return this.aura.getItemAura(this.getScaleForShadow());
  }

  private applyTextStroke(): void {
    if (this.sentenceTextStyles.stroke) {
      this.textFabricObject.set({
        strokeWidth: this.sentenceTextStyles.fontSize * this.sentenceTextStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        strokeLineJoin: 'round',
        paintFirst: 'stroke',
        stroke: rgbToHexString(this.sentenceTextStyles.strokeColor, 1),
      });
    } else if (!this.sentenceTextStyles.stroke) {
      this.textFabricObject.set({
        strokeWidth: 0,
        strokeLineJoin: 'miter',
        paintFirst: 'fill',
        stroke: undefined,
      });
    }
  }

  private updateGroupDimensions(): void {
    this.fabricObject.set({
      width: this.backgroundFabricObject.width,
      height: this.backgroundFabricObject.height,
    });
  }

  private updateBackgroundDimensions(): void {
    this.backgroundFabricObject.set({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });
  }

  private centerAlignItems(): void {
    this.backgroundFabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
    this.textFabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
  }

  private async ensureFontsAreLoaded(): Promise<void> {
    await addFontsAsync([this.sentenceTextStyles.getFontFamilyToLoad()]);
  }

  private initEvents(): void {
    this.textFabricObject.on('editing:entered', this.onTextEditingEnter.bind(this));
    this.textFabricObject.on('editing:exited', this.onTextEditingExit.bind(this));
    this.textFabricObject.on('changed', this.onTextChanged.bind(this));
  }

  private async onTextEditingExit(): Promise<void> {
    if (this.hasUserEdited) {
      this.previousWordDuringOnCanvasEditing = undefined;
      await this.updateFromObject({words: getSubtitlesWordArrayAfterTextEdited(this.text, this.startTime, this.endTime)});
    }

    await this.transcriptItem.onTextEditingExit();
  }

  private async onTextEditingEnter(): Promise<void> {
    await this.transcriptItem.onTextEditingEnter();
  }

  private async onTextChanged(): Promise<void> {
    if (this.transcriptItem.page.poster.isPlaying()) {
      await this.transcriptItem.page.poster.pause();
    }

    let editedSubtitleText = this.textFabricObject.text;

    if (this.animationStyle === SubtitleTemplateType.SINGLE_WORD) {
      const currentWord = this.getCurrentWord();
      if (!currentWord) {
        throw new Error(`No word active at time ${this.transcriptItem.page.poster.getCurrentTime()}`);
      }

      if (!this.previousWordDuringOnCanvasEditing) {
        this.previousWordDuringOnCanvasEditing = currentWord.text;
      }

      const {start} = currentWord.getStartAndEndIndicesOfCurrentWordInSubtitle();

      const textBeforeCurrentWord = this.text.slice(0, start);

      const end = start + this.previousWordDuringOnCanvasEditing.length;

      let textAfterCurrentWord = this.text.slice(end);

      if (editedSubtitleText === '') {
        textAfterCurrentWord = textAfterCurrentWord.trim();
      }

      editedSubtitleText = `${textBeforeCurrentWord}${this.textFabricObject.text}${textAfterCurrentWord}`;

      this.previousWordDuringOnCanvasEditing = this.textFabricObject.text;
    }

    await this.updateFromObject({text: editedSubtitleText, hasUserEdited: true}, {undoable: false});

    this.transcriptItem.onTextChanged();
  }
}

export const createSubtitleFromObject = async (transcriptItem: TranscriptItem, subtitleObject: DeepPartial<SubtitleObject>): Promise<Subtitle> => {
  const subtitle = new Subtitle(transcriptItem);
  await subtitle.updateFromObject(subtitleObject, {
    updateRedux: false,
    undoable: false,
  });

  return subtitle;
};

export const createSubtitleFromObjectAndAddToTranscript = async (transcriptItem: TranscriptItem, subtitleObject: DeepPartial<SubtitleObject>): Promise<Subtitle> => {
  const subtitle = await createSubtitleFromObject(transcriptItem, subtitleObject);
  subtitle.addSubtitleToTranscript();
  return subtitle;
};

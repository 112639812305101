export interface ActivityIndicatorProps {
  type: ActivityIndicatorType;
  size: ActivityIndicatorSize;
  className?: string;
}

export enum ActivityIndicatorType {
  NEW,
  NEW_PULSE,
  LOADING,
  SUCCESS,
  ERROR,
}

export enum ActivityIndicatorSize {
  SIZE_16,
}

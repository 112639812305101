import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {AddAiSubtitlesGenerationResultReduxProps, AiSubtitlesPanelReduxState, SelectableItemWithAudio} from '@Panels/ai-subtitle-panel/ai-subtitle-panel.types';
import {AiTranscriptSupportedLanguage} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';

const initialState: AiSubtitlesPanelReduxState = {
  aiSubtitlesPanel: {
    items: {},
    results: {},
    areUploadsBeingProcessed: false,
    inputLanguage: AiTranscriptSupportedLanguage.ENGLISH,
    hasLanguageSelectorJustClosed: false,
  },
  generationsBatch: {
    currentNumberOfCompletedGenerations: 0,
    totalItemsToGenerateSubtitlesFor: 0,
  },
};

const aiSubtitleSlice = createSlice({
  name: 'AiSubtitle',
  initialState,
  reducers: {
    setAiSubtitlesGenerationsBatch: (state, action: PayloadAction<number>) => {
      state.generationsBatch.currentNumberOfCompletedGenerations = 0;
      state.generationsBatch.totalItemsToGenerateSubtitlesFor = action.payload;
    },

    updateCurrentNumberOfCompletedAiSubtitleGenerations: (state, action: PayloadAction<number>) => {
      state.generationsBatch.currentNumberOfCompletedGenerations = action.payload;
    },

    setHasLanguageSelectorJustClosed: (state, action: PayloadAction<boolean>) => {
      state.aiSubtitlesPanel.hasLanguageSelectorJustClosed = action.payload;
    },

    setAiSubtitlesGenerationLanguage: (state, action: PayloadAction<AiTranscriptSupportedLanguage>) => {
      state.aiSubtitlesPanel.inputLanguage = action.payload;
    },

    setAiSubtitlesPanelItems: (state, action: PayloadAction<SelectableItemWithAudio[]>) => {
      state.aiSubtitlesPanel.items = {};

      action.payload.forEach((item) => {
        state.aiSubtitlesPanel.items[item.id] = item;
      });
    },

    addAiSubtitlesPanelItems: (state, action: PayloadAction<SelectableItemWithAudio[]>): void => {
      action.payload.forEach((item) => {
        state.aiSubtitlesPanel.items[item.id] = item;
      });
    },

    setAreUploadsBeingProcessed: (state, action: PayloadAction<boolean>): void => {
      state.aiSubtitlesPanel.areUploadsBeingProcessed = action.payload;
    },

    toggleAiSubtitlePanelItemSelection: (state, action: PayloadAction<string>) => {
      state.aiSubtitlesPanel.items[action.payload].isSelected = !state.aiSubtitlesPanel.items[action.payload].isSelected;
    },

    selectAiSubtitlePanelItems: (state, action: PayloadAction<Array<string>>) => {
      action.payload.forEach((id) => {
        state.aiSubtitlesPanel.items[id].isSelected = true;
      });
    },

    unSelectAiSubtitlePanelItems: (state, action: PayloadAction<Array<string>>) => {
      action.payload.forEach((id) => {
        state.aiSubtitlesPanel.items[id].isSelected = false;
      });
    },

    addAiSubtitlesGenerationResults: (state, action: PayloadAction<AddAiSubtitlesGenerationResultReduxProps>) => {
      state.aiSubtitlesPanel.results = {...state.aiSubtitlesPanel.results, [action.payload.id]: action.payload.result};
    },

    clearGeneratedResults: (state) => {
      state.aiSubtitlesPanel.results = {};
    },
  },
});

export const {
  setAiSubtitlesGenerationsBatch,
  updateCurrentNumberOfCompletedAiSubtitleGenerations,
  setHasLanguageSelectorJustClosed,
  setAiSubtitlesGenerationLanguage,
  setAiSubtitlesPanelItems,
  addAiSubtitlesPanelItems,
  selectAiSubtitlePanelItems,
  setAreUploadsBeingProcessed,
  toggleAiSubtitlePanelItemSelection,
  unSelectAiSubtitlePanelItems,
  addAiSubtitlesGenerationResults,
  clearGeneratedResults,
} = aiSubtitleSlice.actions;

export const aiSubtitleReducer = aiSubtitleSlice.reducer;
